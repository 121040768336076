export const GET_NUMBER_TABS= 'GET_NUMBER_TABS';
export const GET_NUMBER_CART= 'GET_NUMBER_CART';

export const getNumberTabs = numberTabs => ({
    type: GET_NUMBER_TABS,
    payload: {numberTabs}
});
export const getNumberCart = numberCart => ({
    type: GET_NUMBER_CART,
    payload: {numberCart}
});