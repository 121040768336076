import { GET_NUMBER_TABS, GET_NUMBER_CART } from "./globalReduxActions";

const initialState = {
  dataNumberTabs: 3,
  dataNumberCart: getNumberCart()
};

export default function globalReduxReducer(state = initialState, action) {
  switch (action.type) {
    case GET_NUMBER_TABS:
      return {
        ...state,
        dataNumberTabs: action.payload.numberTabs,
      };
    case GET_NUMBER_CART:
      return {
        ...state,
        dataNumberCart: action.payload.numberCart,
      };

    default:
      return state;
  }
}

function getNumberCart () {
  var data = [];
  data = JSON.parse(localStorage.getItem('cart')) || [];
  return data.length
}
