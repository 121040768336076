import { 
    GET_DATA_COMPANIES
 } from "./companyActions";

 const initialState = {
    dataCompanies: [],
  };

  export default function companyReducer(state = initialState, action){
      switch(action.type){
          case GET_DATA_COMPANIES:
              return {
                  ...state,
                  dataCompanies: action.payload.company
              };
        
        default:
            return state;
      }
  }